import React, { Component } from "react";

import Get6Keys from "./Get6Keys";
import Modal from "react-bootstrap/Modal";

class ClaimPoint extends Component {
  state = {
    code: null,
    show: false,
  };

  handleClose = () => {
    this.setState({ show: false });
  };
  handleOpen = () => {
    this.setState({ show: true });
  };

  waitingBTN = (id) => {
    const bt = document.getElementById(id);
    bt.classList.add("disable-link");
    bt.innerHTML = "wait...";
  };
  unWaitingBTN = (id, o) => {
    const bt = document.getElementById(id);
    bt.classList.remove("disable-link");
    bt.innerHTML = o;
  };
  error = (m) => {
    const e = document.getElementById("err");
    e.innerHTML = m;
  };
  suc = (m) => {
    const s = document.getElementById("suc");
    s.innerHTML = m;
  };
  reduceBonusKey = async (id) => {
    const get = await fetch("https://api.the369.xyz/reduceBonusKey", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        user_id: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("update-btn").click();
    }
    if (get.status === "failed") {
    }
  };
  reduceBuyKey = async (id) => {
    const get = await fetch("https://api.the369.xyz/reduceBuyKey", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        user_id: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("update-btn").click();
    }
    if (get.status === "failed") {
    }
  };

  insertLog = async (w, k) => {
    const get = await fetch("https://api.the369.xyz/insertPointLog", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        key: k,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
    }
    if (get.status === "failed") {
    }
  };

  keyExist = async (k) => {
    const get = await fetch("https://api.the369.xyz/keywordExist", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        key: k,
      }),
    }).then((res) => res.json());
    return get;
  };

  keyUpdate = async (k) => {
    const get = await fetch("https://api.the369.xyz/updateKeyCapacity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        key: k,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      return true;
    }
    if (get.status === "failed") {
      return false;
    }
  };
  insertUserKey = async (w, k) => {
    const get = await fetch("https://api.the369.xyz/insertPointUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        key: k,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      return true;
    }
    if (get.status === "failed") {
      return false;
    }
  };
  OldUserKey = async (w, k) => {
    const get = await fetch("https://api.the369.xyz/keywordUserEntered", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        key: k,
      }),
    }).then((res) => res.json());
    return get;
  };
  addScoreXp = async (w, x, s) => {
    const get = await fetch("https://api.the369.xyz/addScoreXp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        xp: x,
        score: s,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      return true;
    }
    if (get.status === "failed") {
      return false;
    }
  };
  setInput = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      code: {
        ...prevState.code,
        [name]: value,
      },
    }));
  };
  handleCheckCode = async () => {
    this.waitingBTN("btn");
    this.error("");
    this.suc("");
    const exist = await this.keyExist(
      this.state.code.code.toLowerCase().replaceAll(" ", "")
    );
    if (exist.length === 0) {
      this.error("Wrong key");
      this.insertLog(this.props.wallet, this.state.code.code);
      if (this.props.user.bonus_key !== 0) {
        this.reduceBonusKey(this.props.user.user_id);
      } else {
        this.reduceBuyKey(this.props.user.user_id);
      }
    } else {
      if (exist[0].capacity === 0) {
        this.error("Expired key");
        this.insertLog(this.props.wallet, this.state.code.code);
      } else {
        const userCheck = await this.OldUserKey(
          this.props.wallet,
          exist[0].keyword
        );
        if (userCheck.length !== 0) {
          this.error("This key has already been used");
          this.insertLog(this.props.wallet, this.state.code.code);
        } else {
          const add = await this.addScoreXp(
            this.props.wallet,
            exist[0].xp,
            exist[0].point
          );
          if (add) {
            const insert = await this.insertUserKey(
              this.props.wallet,
              exist[0].keyword
            );
            if (insert) {
              const up = await this.keyUpdate(exist[0].keyword);
              if (up) {
                if (exist[0].point > 1) {
                  this.suc(
                    `Congrats. Your key worth  ${
                      exist[0].point !== 0 ? `${exist[0].point} POINTS` : ""
                    }  `
                  );
                } else {
                  this.suc(
                    `Congrats. Your key worth  ${
                      exist[0].point !== 0 ? `${exist[0].point} POINT` : ""
                    }  `
                  );
                }

                document.getElementById("update-btn").click();
              } else {
                this.error("Something wrong, try again later");
              }
            } else {
              this.error("Something wrong, try again later");
            }
          } else {
            this.error("Something wrong, try again later");
          }
        }
      }
    }
    this.unWaitingBTN("btn", "Check The Key");
  };
  render() {
    const key = this.props.user.buy_key + this.props.user.bonus_key;
    return (
      <>
        <button
          id="update-btn"
          className="d-none"
          onClick={this.props.updateUser}
        ></button>
        <button
          id="refresh"
          className="d-none"
          onClick={this.props.refreshQuests}
        ></button>
        <div className="col-12 d-flex justify-content-center align-self-center position-relative">
          <div className=" d-flex flex-column ">
            <div className="row m-3 justify-content-center align-self-center w-100">
              <div className="col-12  h-400 div-work d-flex justify-content-center align-items-center max-w-992 d-flex flex-column position-relative">
                <span className="fs-4 fw-bold text-muted mb-3 text-center ">
                  Enter the key to claim your point
                </span>
                <span className="d-flex">
                  <input
                    onChange={this.setInput}
                    name="code"
                    className={
                      key !== 0
                        ? "form-control form-control-lg m-2 w-300 border-success bg-dark text-white"
                        : "form-control form-control-lg m-2 w-300 border-danger disable-link bg-dark text-white "
                    }
                    type="text"
                    placeholder="Enter Key"
                  />

                  <span
                    className={
                      key < 10
                        ? " text-center border-red  h-100 p-1 d-flex flex-column BR-5 pt-2 "
                        : " text-center border-green  h-100 p-1 d-flex flex-column BR-5 pt-2"
                    }
                  >
                    <img
                      alt="AKU XP"
                      className="mt-2"
                      width="20"
                      src="./asset/logo/soulbadge.png"
                    />
                    <span className="c-reg  fs-8">{key}</span>
                  </span>
                </span>

                <span className="fs-6 text-success" id="suc"></span>
                <span className="fs-6 text-danger" id="err"></span>
                {key === 0 ? (
                  <>
                    <button id="btn" className="btn-s bg-red mt-2 disable-link">
                      Disable
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        this.handleCheckCode();
                      }}
                      id="btn"
                      className="btn-s bg-green mt-2"
                    >
                      Check The Key
                    </button>
                  </>
                )}
                <Get6Keys
                  refreshQuests={this.props.refreshQuests}
                  updateUser={this.props.updateUser}
                  user={this.props.user}
                />
                <i
                  onClick={this.handleOpen}
                  class="bi bi-info-circle text-warning position-absolute bottom-0 end-0 fs-5 me-2 mb-2 c-pointer"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={() => {
            this.handleClose();
          }}
          // backdrop="static"
          keyboard={false}
          centered
          size="sm"
          contentClassName="bg-dark"
        >
          {/* <Modal.Header closeButton>
            <Modal.Title>
              <i className="bi bi-key"></i>
            </Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            <div className="key-info">
              {" "}
              9 +{" "}
              <img
                alt="AKU"
                className=""
                width="20"
                height="20"
                src="./asset/Menu/aku.png"
              />{" "}
              (Souls(
              <img
                alt="AKU"
                className="mx-1"
                width="20"
                height="20"
                src="./asset/logo/soulbadge.png"
              />
              ) per week)
              <br />
              Purchased Souls will not be burned <br />
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ClaimPoint;
