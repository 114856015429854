import React, { Component } from "react";

class Menu extends Component {
  state = {
    user: this.props.user,
  };

  render() {
    const u = this.props.user;
    return (
      <>
        <nav className="navbar navbar-dark bg-black fixed-top border-down">
          <div className="container-fluid d-flex justify-content-start">
            <button
              className="btn-menu"
              type="a"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasDarkNavbar"
              aria-controls="offcanvasDarkNavbar"
            >
              {/* <!-- <span className="navbar-toggler-icon"></span> --> */}
              <i className="bi bi-list fs-3 text-white"></i>
            </button>

            <div className="max-h-56 ms-auto justify-content-start d-flex align-items-center">
              {/*  */}
              {this.props.isConnected ? (
                <>
                  {this.props.isMember ? (
                    <>
                      <img
                        alt="AKU PROFILE"
                        onClick={this.props.handleSettingPage}
                        className={
                          this.props.location === "setting"
                            ? "profile-pic me-2 disable-link"
                            : "profile-pic me-2 c-pointer"
                        }
                        width="50"
                        height="50"
                        src={`./NFTImages/${u.profilepic_id}.jpg`}
                      />
                      <span className="badge text-bg-dark me-1 min-h-30 justify-content-start d-flex align-items-center">
                        <img
                          alt="AKU XP"
                          className=""
                          width="20"
                          height="20"
                          src="./asset/Menu/xp.png"
                        />
                        <span className="fs-7 ms-1 fw-bold" key={u.xp}>
                          {u.xp}
                        </span>
                      </span>
                      <span className="badge text-bg-dark me-1 min-h-30 justify-content-start d-flex align-items-center">
                        <img
                          alt="AKU"
                          className=""
                          width="20"
                          height="20"
                          src="./asset/Menu/aku.png"
                        />
                        <span className="fs-7 ms-1 fw-bold">
                          {this.props.NFTCount}
                        </span>
                      </span>
                      <span
                        onClick={this.props.handleMessagesPage}
                        className={
                          this.props.location === "messages"
                            ? " disable-link c-reg badge text-bg-dark me-1 min-h-30 justify-content-start d-flex align-items-center position-relative"
                            : " c-pointer badge text-bg-dark me-1 min-h-30 justify-content-start d-flex align-items-center position-relative"
                        }
                      >
                        <i
                          className={
                            this.props.newMessage === 0
                              ? "bi bi-envelope-open-fill fs-6"
                              : "bi bi-envelope-fill fs-6"
                          }
                        ></i>
                        {this.props.newMessage === 0 ? (
                          ""
                        ) : (
                          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {this.props.newMessage}
                            <span className="visually-hidden">
                              unread messages
                            </span>
                          </span>
                        )}
                      </span>
                      {this.props.rank !== 0 ? (
                        <>
                          <span
                            onClick={this.props.handleLeaderboardPage}
                            className={
                              this.props.location === "leaderboard"
                                ? "disable-link badge text-bg-dark me-1 min-h-30 justify-content-start d-flex align-items-center"
                                : "c-pointer badge text-bg-dark me-1 min-h-30 justify-content-start d-flex align-items-center"
                            }
                          >
                            Rank{" "}
                            <span className="fs-7 ms-1 fw-bold">
                              #{this.props.rank}
                            </span>
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <button
                        onClick={this.props.handleSignupPage}
                        className="btn-s bg-red align-self-start ms-3 mt-3"
                      >
                        Sign Up
                      </button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <button
                    onClick={this.props.handdleconnect}
                    className="btn-s bg-red align-self-start ms-3 mt-3"
                  >
                    Connect Wallet
                  </button>
                </>
              )}

              {/*   */}
            </div>
            <div
              className="offcanvas offcanvas-start bg-black op-8 border border-0"
              tabIndex="-1"
              id="offcanvasDarkNavbar"
              aria-labelledby="offcanvasDarkNavbarLabel"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasDarkNavbar"
            >
              <div className="offcanvas-header text-white max-h-56">
                {/* <!-- <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button> --> */}
              </div>
              <div className="offcanvas-body mt-3">
                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                  <li className="nav-item">
                    <button
                      className={
                        this.props.location === "home"
                          ? "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent c-reg disable-link"
                          : "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent"
                      }
                      aria-current="page"
                      onClick={this.props.handleHomePage}
                    >
                      <i
                        className={
                          this.props.location === "home"
                            ? "fs-3 bi-house  navbar-item-custom nav-active"
                            : "fs-3 bi-house btn-369 bg-dark navbar-item-custom"
                        }
                      ></i>
                      <span className="ms-3 text-muted">Home</span>
                    </button>
                  </li>
                  {this.props.isConnected && this.props.isMember ? (
                    <>
                      <li className="nav-item">
                        <button
                          className={
                            this.props.location === "quests"
                              ? "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent c-reg disable-link"
                              : "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent"
                          }
                          aria-current="page"
                          onClick={this.props.handleQuestsPage}
                        >
                          <i
                            className={
                              this.props.location === "quests"
                                ? "fs-3 bi-lightning  navbar-item-custom nav-active"
                                : "fs-3 bi-lightning btn-369 bg-dark navbar-item-custom"
                            }
                          ></i>
                          <span className="ms-3 text-muted">Quests</span>
                        </button>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {this.props.isConnected && this.props.isMember ? (
                    <>
                      <li className="nav-item">
                        <button
                          className={
                            this.props.location === "leaderboard"
                              ? "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent c-reg disable-link"
                              : "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent"
                          }
                          aria-current="page"
                          onClick={this.props.handleLeaderboardPage}
                        >
                          <i
                            className={
                              this.props.location === "leaderboard"
                                ? "fs-3 bi-bar-chart-line  navbar-item-custom nav-active"
                                : "fs-3 bi-bar-chart-line btn-369 bg-dark navbar-item-custom"
                            }
                          ></i>
                          <span className="ms-3 text-muted">Leaderboard</span>
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={
                            this.props.location === "messages"
                              ? "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent c-reg disable-link"
                              : "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent"
                          }
                          aria-current="page"
                          onClick={this.props.handleMessagesPage}
                        >
                          <i
                            className={
                              this.props.location === "messages"
                                ? "fs-3 bi-chat-text  navbar-item-custom nav-active"
                                : "fs-3 bi-chat-text btn-369 bg-dark navbar-item-custom"
                            }
                          ></i>
                          <span className="ms-3 text-muted">Messages</span>
                        </button>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="nav-item">
                        <button
                          className={
                            this.props.location === "about"
                              ? "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent c-reg disable-link"
                              : "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent"
                          }
                          aria-current="page"
                          onClick={this.props.handleAboutPage}
                        >
                          <i
                            className={
                              this.props.location === "about"
                                ? "fs-3 bi-eye  navbar-item-custom nav-active"
                                : "fs-3 bi-eye btn-369 bg-dark navbar-item-custom"
                            }
                          ></i>
                          <span className="ms-3 text-muted">About</span>
                        </button>
                      </li>
                    </>
                  )}

                  {this.props.isConnected && this.props.isMember ? (
                    <>
                      <li className="nav-item">
                        <button
                          className={
                            this.props.location === "setting"
                              ? "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent c-reg disable-link"
                              : "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent"
                          }
                          aria-current="page"
                          onClick={this.props.handleSettingPage}
                        >
                          <i
                            className={
                              this.props.location === "setting"
                                ? "fs-3 bi-gear  navbar-item-custom nav-active"
                                : "fs-3 bi-gear btn-369 bg-dark navbar-item-custom"
                            }
                          ></i>
                          <span className="ms-3 text-muted">Setting</span>
                        </button>
                      </li>
                      {/* <li className="nav-item">
                        <button
                          className={
                            this.props.location === "chatgpt"
                              ? "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent c-reg disable-link"
                              : "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent"
                          }
                          aria-current="page"
                          onClick={this.props.handleChatgptPage}
                        >
                          <i
                            className={
                              this.props.location === "chatgpt"
                                ? "fs-3 bi-sign-railroad  navbar-item-custom nav-active"
                                : "fs-3 bi-sign-railroad btn-369 bg-dark navbar-item-custom"
                            }
                          ></i>
                          <span className="ms-3 text-muted">Chat GPT</span>
                        </button>
                      </li> */}
                    </>
                  ) : (
                    <></>
                  )}
                  <li className="nav-item">
                    <button
                      className={
                        this.props.location === "convert"
                          ? "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent c-reg disable-link"
                          : "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent"
                      }
                      aria-current="page"
                      onClick={this.props.handleConvertPage}
                    >
                      <i
                        className={
                          this.props.location === "convert"
                            ? "fs-3 bi-arrow-left-right  navbar-item-custom nav-active"
                            : "fs-3 bi-arrow-left-right btn-369 bg-dark navbar-item-custom"
                        }
                      ></i>
                      <span className="ms-3 text-muted">Translator</span>
                    </button>
                  </li>
                  {this.props.isConnected && this.props.isMember ? (
                    <>
                      <li className="nav-item">
                        <button
                          className="nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center  btn btn-transparent c-reg disable-link"
                          aria-current="page"
                        >
                          <i className="fs-3 bi-shop navbar-item-custom text-muted p-l-8"></i>
                          <span className="ms-3 text-muted">Shop</span>
                        </button>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  <li className="nav-item">
                    <button
                      className="nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center  btn btn-transparent c-reg disable-link"
                      aria-current="page"
                      onClick={this.props.handleMintPage}
                    >
                      <i className="fs-3 bi-box-arrow-in-down navbar-item-custom text-muted p-l-8"></i>
                      <span className="ms-3 text-muted">Mint</span>
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center  btn btn-transparent c-reg disable-link"
                      aria-current="page"
                    >
                      <i className="fs-3 bi-infinity navbar-item-custom text-muted p-l-8"></i>
                      <span className="ms-3 text-muted">Game</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

export default Menu;
